import VueCurrencyFilter from 'vue-currency-filter';
import VueMoment from 'vue-moment';
import VueClipboard from 'vue-clipboard2';

const GlobalFilters = {
	install(Vue) {
		Vue.use(VueMoment);
		Vue.use(VueClipboard);
		Vue.use(VueCurrencyFilter, [
			{
				// default name 'currency'
				symbol: '€',
				thousandsSeparator: ',',
				fractionCount: 2,
				fractionSeparator: '.',
				symbolPosition: 'front',
				symbolSpacing: true,
				avoidEmptyDecimals: '',
			},
			{
				name: 'euro',
				symbol: '€',
				thousandsSeparator: ',',
				fractionCount: 2,
				fractionSeparator: '.',
				symbolPosition: 'front',
				symbolSpacing: true,
				avoidEmptyDecimals: '',
			},
			{
				name: 'dollar',
				symbol: '$',
				thousandsSeparator: ' ',
				fractionCount: 2,
				fractionSeparator: '.',
				symbolPosition: 'front',
				symbolSpacing: true,
				avoidEmptyDecimals: '',
			},
			{
				name: 'tl',
				symbol: '₺',
				thousandsSeparator: '.',
				fractionCount: 2,
				fractionSeparator: ',',
				symbolPosition: 'front',
				symbolSpacing: true,
				avoidEmptyDecimals: '',
			},
		]);
	},
};

VueClipboard.config.autoSetContainer = true;

export default GlobalFilters;
