/* eslint-disable no-unused-vars */
import VuetifyMask from 'vuetify-mask';
import VueMask from 'v-mask';
import VueSocialSharing from 'vue-social-sharing';

const GlobalPlugins = {
	install(Vue) {
		Vue.use(VuetifyMask);
		Vue.use(VueMask);
		Vue.use(VueSocialSharing);
	},
};

export default GlobalPlugins;
