/* eslint-disable no-underscore-dangle */
import HttpService from '@/common/axios.http.service';
import { SHOW_MESSAGE } from '@/store/modules/snackBar.module';

const ApiService = {
	store: null,

	init(store) {
		this.store = store;
		HttpService.errorHandler = this.errorHandler.bind(this); // Bağlama önemli!
		HttpService.customHeaders = this.setCustomHeaders.bind(this);
		HttpService.init(store);
	},

	setCustomHeaders() {
		const { VUE_APP_TITLE } = process.env;
		const { appVersion } = this.store.getters;

		return [
			{ key: 'clisrc', value: VUE_APP_TITLE },
			{ key: 'cliver', value: appVersion },
		];
	},

	errorHandler(result) {
		const defaultError = { responseText: result.message };
		const err = result.response?.data || defaultError;

		if (err.responseCode === 600 || err.responseCode === 601) {
			this._handleCriticalError(err.responseText);
		} else if (err.responseCode === 6003) {
			this._handleLogout();
		} else {
			this._showErrorMessage(err.responseText);
		}

		return Promise.reject(err);
	},

	post(url, payload, config) {
		return HttpService.post(url, payload, config)
			.then((response) => response)
			.catch((error) => Promise.reject(error));
	},

	// Private methods
	_showErrorMessage(message) {
		this.store.dispatch(SHOW_MESSAGE, { color: 'red', message });
	},

	_handleCriticalError(message) {
		this._showErrorMessage(message);
		this._handleLogout();
	},

	_handleLogout() {
		setTimeout(() => {
			this.store.dispatch('logout');
		}, 500);
	},
};

export default ApiService;
