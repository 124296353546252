<template>
  <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.color" :multi-line="snackbar.multiline">
    {{ snackbar.message }}
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" text @click="closeSnackbar">{{ $t('close') }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { DISPOSE } from '@/store/modules/snackBar.module';

export default {
  name: 'SnackBarMessage',
  computed: {
    snackbar() {
      return this.$store.state.snackBar;
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.dispatch(DISPOSE);
    },
  },
};
</script>
