/* eslint-disable no-param-reassign */
import CloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';

import { capitalize, hexToRgb, sleep, toSlug, toTitleCase } from '@/common/utils';

const GlobalFunctions = {
	install(Vue) {
		Vue.prototype.$cloneDeep = (obj) => CloneDeep(obj);
		Vue.prototype.$debounce = (func, wait, options) => debounce(func, wait, options);
		Vue.prototype.$sleep = sleep;
		Vue.prototype.$toTitleCase = (phrase) => toTitleCase(phrase);
		Vue.prototype.$hexToRgb = (hex) => hexToRgb(hex);
		Vue.prototype.$capitalize = (input) => capitalize(input);
		Vue.prototype.$toSlug = (value, delimiter = '-') => toSlug(value, delimiter);
	},
};

export default GlobalFunctions;
