// action types
export const OPEN_VERIFY_MODAL = 'openVerifyModal';
export const HIDE_VERIFY_MODAL = 'hideVerifyModal';
export const VERIFY = 'verifyUser';

const state = {
	verificationModal: false,
	user: null,
	onSuccess: null,
};

const getters = {
	verificationModal: (state) => state.verificationModal,
};

const actions = {
	[OPEN_VERIFY_MODAL](context, payload) {
		context.commit(OPEN_VERIFY_MODAL, payload);
	},
	async [VERIFY](context, payload) {
		const success = await context.state.onSuccess(payload);
		if (success) {
			context.commit(VERIFY, payload);
		}
	},
	[HIDE_VERIFY_MODAL](context) {
		context.commit(HIDE_VERIFY_MODAL);
	},
};

const mutations = {
	[OPEN_VERIFY_MODAL](state, { success }) {
		state.onSuccess = success;
		state.verificationModal = true;
	},
	[VERIFY](state, payload) {
		state.user = payload;
		state.verificationModal = false;
		state.onSuccess = null;
	},
	[HIDE_VERIFY_MODAL](state) {
		state.user = null;
		state.verificationModal = false;
		state.onSuccess = null;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
