/* eslint-disable no-unused-vars */
import ApiService from '@/common/api.service';
import EventBus from '@/event-bus';

import packageInfo from '../../../package.json';

// action types
export const LOGIN = 'login';
export const VIEW_AS_LOGIN = 'viewAsLogin';
export const GET_PERMISSION = 'getPermission';
export const CHECK_PERMISSION = 'checkPermission';
export const LOGOUT = 'logout';
export const LOGOUT_VIEW_AS = 'logoutViewAs';
export const LOGIN_SSO = 'loginSSO';

// mutation types
export const PURGE_AUTH = 'logOut';
export const PURGE_VIEW_AS_AUTH = 'logOutViewAs';
export const SET_AUTH = 'setUser';
export const SET_TOKEN = 'setToken';
export const SET_VIEW_AS_AUTH = 'setViewAsUser';
export const SET_PERMISSION = 'setPermission';
export const SET_VIEW_AS_PERMISSION = 'setViewAsPermission';
export const SET_ERROR = 'setError';

const state = {
	errors: null,
	user: null,
	viewAsUser: null,
	token: null,
	viewAsUsertoken: null,
	permissions: [],
	viewAsUserPermissions: [],
	viewAs: false,
};

const getters = {
	isAuthenticated: (state) => !!state.user,
	currentUser: (state) => (state.viewAs ? state.viewAsUser : state.user),
	token: (state) => (state.viewAs ? state.viewAsUsertoken : state.token),
	permissions: (state) => (state.viewAs ? state.viewAsUserPermissions : state.permissions),
	isViewUser: (state) => state.viewAs,
	appVersion: () => packageInfo.version,
};

const actions = {
	[GET_PERMISSION](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post('user/getuirights', payload)
				.then((data) => {
					if (!data) return;
					if (payload.viewAs) context.commit(SET_VIEW_AS_PERMISSION, data);
					else context.commit(SET_PERMISSION, data);
					resolve(data);
				})
				.catch((error) => {
					if (error.responseCode !== 6003) context.commit(SET_ERROR, error.responseText);
					reject(error);
				});
		});
	},
	[CHECK_PERMISSION](context, payload) {
		return new Promise((resolve) => {
			if (payload.groupName && context.getters.permissions) {
				const group = payload.groupName.toLowerCase();
				const method = payload.methodName.toLowerCase();
				const hasPermission = context.getters.permissions.some(
					(x) => x.group.toLowerCase() === group && x.name.toLowerCase() === method,
				);
				return resolve(hasPermission);
			}
			return resolve(false);
		});
	},
	[SET_TOKEN](context, token) {
		context.commit(SET_TOKEN, token);
	},
	[LOGIN](context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post(`auth/login`, credentials)
				.then((data) => {
					if (!data) return;
					context.commit(SET_AUTH, data);
					context.dispatch(GET_PERMISSION, { groupName: 'All', viewAs: false }).then(() => {
						resolve(data);
					});
				})
				.catch((error) => {
					context.commit(SET_ERROR, error.responseText);
					reject(error);
				});
		});
	},
	[VIEW_AS_LOGIN](context, user) {
		return new Promise((resolve, reject) => {
			ApiService.post(`user/viewaslogin`, { id: user.userId })
				.then((data) => {
					if (!data) return;
					context.commit(SET_VIEW_AS_AUTH, { ...data, viewName: user.name });
					context.dispatch(GET_PERMISSION, { groupName: 'All', viewAs: true }).then(() => {
						EventBus.$emit('forceRenderBaseComponent');
						resolve(data);
					});
				})
				.catch((error) => {
					context.commit(SET_ERROR, error.responseText);
					reject(error);
				});
		});
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
		context.commit(PURGE_VIEW_AS_AUTH);
	},
	[LOGOUT_VIEW_AS](context) {
		context.commit(PURGE_VIEW_AS_AUTH);
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_TOKEN](state, token) {
		state.token = token;
		state.errors = null;
	},
	[SET_AUTH](state, user) {
		state.user = user;
		state.token = user.token;
		state.errors = null;
	},
	[SET_VIEW_AS_AUTH](state, viewAsUser) {
		state.viewAsUser = viewAsUser;
		state.viewAsUsertoken = viewAsUser.token;
		state.errors = null;
		state.viewAs = true;
	},
	[SET_PERMISSION](state, permissions) {
		state.permissions = permissions;
		state.errors = null;
	},
	[SET_VIEW_AS_PERMISSION](state, permissions) {
		state.viewAsUserPermissions = permissions;
		state.errors = null;
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = null;
		state.token = null;
		state.permissions = [];
		state.errors = null;
		window.localStorage.clear();
	},
	[PURGE_VIEW_AS_AUTH](state) {
		EventBus.$emit('forceRenderBaseComponent');
		state.viewAsUser = null;
		state.viewAsUsertoken = null;
		state.viewAsUserPermissions = [];
		state.errors = null;
		state.viewAs = false;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
