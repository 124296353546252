/* eslint-disable no-shadow */

// action types
export const SHOW_MESSAGE = 'showMessage';
export const DISPOSE = 'dispose';

// mutation types
const state = {
	visible: false,
	message: null,
	multiline: false,
	color: 'primary',
	timeout: 3000,
};

const getters = {
	message: (state) => state.message,
};

const actions = {
	[SHOW_MESSAGE](context, payload) {
		context.commit(SHOW_MESSAGE, payload);
		setTimeout(() => {
			context.dispatch(DISPOSE);
		}, context.state.timeout);
	},
	[DISPOSE](context) {
		context.commit(DISPOSE);
	},
};

const mutations = {
	[SHOW_MESSAGE](state, payload) {
		state.message = payload.message;
		state.visible = true;
		if (payload.multiline) state.multiline = payload.multiline;
		if (payload.timeout) state.timeout = payload.timeout;
		if (payload.color) state.color = payload.color;
	},
	[DISPOSE](state) {
		state.visible = false;
		state.timeout = 3000;
		state.message = null;
		state.multiline = false;

		setTimeout(() => {
			state.color = null;
		}, 500);
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
