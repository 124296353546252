import ApiService from '@/common/api.service';
import Vue from 'vue';

export const SET_DISPLAY_TYPE = 'SET_DISPLAY_TYPE';
export const SORT_LIST = 'SORT_LIST';

export const APPLY_FILTER = 'APPLY_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const UPDATE_FILTER_PRESET = 'UPDATE_FILTER_PRESET';
export const SET_REQUEST_READY_STATUS = 'SET_REQUEST_READY_STATUS';

export const FETCH_PRESET = 'FETCH_PRESET';
export const FETCH_STATUS = 'FETCH_STATUS';
export const RESET_IMMEDIATELY_FILTER = 'RESET_IMMEDIATELY_FILTER';
export const FETCH_CAR_LIST = 'FETCH_CAR_LIST';

export const FETCH_TEMP_CAR_LIST = 'FETCH_TEMP_CAR_LIST';

export const SET_CAR_MOBILE_TMP_LIST = 'SET_CAR_MOBILE_TMP_LIST';
export const GET_CAR_BY_ID = 'GET_CAR_BY_ID';

export const GET_MORE_CARS = 'GET_MORE_CARS';

export const TOGGLE_FAVORITE_CAR = 'TOGGLE_FAVORITE_CAR';
export const FETCH_POPULAR_CAR_LIST = 'FETCH_POPULAR_CAR_LIST';

export const RESERVE_CAR = 'RESERVE_CAR';
export const PAYMENT_RESERVATION_CAR = 'PAYMENT_RESERVATION_CAR';
export const VALID_SMS_CODE = 'VALID_SMS_CODE';
export const RESEND_RESERVATION_VALIDATION_CODE = 'RESEND_RESERVATION_VALIDATION_CODE';

// backoffice
export const BO_FETCH_CAR_LIST_GRID_DATA = 'BO_FETCH_CAR_LIST_GRID_DATA';
export const BO_FETCH_GRID_DATA = 'BO_FETCH_GRID_DATA';
export const BO_FETCH_TOTAL_COUNT = 'BO_FETCH_TOTAL_COUNT';
export const BO_REMOVE_LISTED = 'BO_REMOVE_LISTED';
export const BO_RESERVATION_FETCH_GRID_DATA = 'BO_RESERVATION_FETCH_GRID_DATA';

const endPoints = {
	getPreset: 'listing/getpreset',
	getList: 'listing/getlist',
	getDetail: 'listing/getdetail',
	addFavorite: 'listing/addfavorite',
	removeFavorite: 'listing/removefavorite',
	getGridData: 'listing/getgriddata',
	getPopularList: 'listing/getpopularlist',
	removeListed: 'listing/removelisted',
	reserve: 'listingreservation/reserve',
	paymentReservation: 'listingreservation/paymentreservation',
	validSmsCode: 'listingreservation/validSmsCode',
	resendReservationValidateCode: 'listingreservation/resendreservationvalidatecode',
	getReserveGridData: 'listingreservation/getgriddata',
};

const rootDefaultState = () => ({
	fetching: false,
	immediately: false,
});

const getDefaultState = () => ({
	filter: {
		panels: [0],
		preset: null,
		payload: {
			pageNumber: 1,
			sortType: 0,
			searchText: null,
			categories: [],
			brands: [],
			models: [],
			bodyTypes: [],
			transmissionTypes: [],
			fuelTypes: [],
			tractionTypes: [],
			colors: [],
			versions: [],
			startYear: null,
			endYear: null,
			startKilometer: null,
			endKilometer: null,
			startPrice: null,
			endPrice: null,
			opportunity: false,
			cities: [],
			locations: [],
		},
		readyForRequest: true,
	},
	title: {
		displayType: 0,
		selectedSortField: 0,
	},
	totalCount: 0,
	cars: [],
	carsForMobileTmp: [],
	forMobileTmpTotalCount: 0,
	favoriteCars: [],
	popularCars: [],
});

const getBODefaultState = () => ({
	backoffice: {
		carList: {
			data: [],
			count: 0,
		},
		reserveCarList: {
			data: [],
			count: 0,
		},
	},
});

const state = {
	...rootDefaultState(),
	...getDefaultState(),
	...getBODefaultState(),
};

const getters = {
	isImmediatelyFilter: (state) => state.immediately,
	filterPanels: (state) => state.filter.panels,
	filterPreset: (state) => state.filter.preset,
	filterPayload: (state) => state.filter.payload,
	readyForRequest: (state) => state.filter.readyForRequest,

	displayType: (state) => state.title.displayType,
	sortFields: (state) => state.title.sortFields,
	selectedSortField: (state) => state.title.selectedSortField,

	isFetching: (state) => state.fetching,
	listingCars: (state) =>
		state.cars.map((x) => ({
			...x,
			photos: x.photos.length > 0 ? x.photos.map((y) => y) : [`${Vue.prototype.$assetPath}photo-not-ready.svg`],
		})),
	listingFilteredCount: (state) => state.cars.length,
	listingTotalCount: (state) => state.totalCount,
	hasMoreData: (state) => state.totalCount > state.cars.length,

	forMobileTmpTotalCount: (state) => state.forMobileTmpTotalCount,

	favoriteCars: (state) => state.favoriteCars,
	popularCars: (state) => state.popularCars,

	// bacloffice
	bo_carListData: (state) => state.backoffice.carList.data,
	bo_carListTotalCount: (state) => state.backoffice.carList.count,

	bo_reserveCarListData: (state) => state.backoffice.reserveCarList.data,
	bo_reserveCarListTotalCount: (state) => state.backoffice.reserveCarList.count,
};

const actions = {
	[SET_DISPLAY_TYPE](context, payload) {
		context.commit(SET_DISPLAY_TYPE, payload);
	},

	[APPLY_FILTER](context, filter) {
		context.commit(APPLY_FILTER, filter);
	},

	[SORT_LIST](context, payload) {
		switch (payload.sortType) {
			case 0:
				context.commit(SORT_LIST, {
					sortType: payload.sortType,
					sortField: '',
				});
				break;
			case 1:
				context.commit(SORT_LIST, {
					sortType: payload.sortType,
					sortField: 'price',
				});
				break;
			case 2:
				context.commit(SORT_LIST, {
					sortType: payload.sortType,
					sortField: '-price',
				});
				break;
			case 3:
				context.commit(SORT_LIST, {
					sortType: payload.sortType,
					sortField: 'kilometer',
				});
				break;
			case 4:
				context.commit(SORT_LIST, {
					sortType: payload.sortType,
					sortField: '-kilometer',
				});
				break;
			case 5:
				context.commit(SORT_LIST, {
					sortType: payload.sortType,
					sortField: 'year',
				});
				break;
			case 6:
				context.commit(SORT_LIST, {
					sortType: payload.sortType,
					sortField: '-year',
				});
				break;
			default:
				context.commit(SORT_LIST, {});
				break;
		}
	},

	[CLEAR_FILTER](context) {
		context.commit(CLEAR_FILTER);
	},

	[GET_MORE_CARS](context) {
		context.commit(GET_MORE_CARS);
	},

	[SET_REQUEST_READY_STATUS](context, payload) {
		context.commit(SET_REQUEST_READY_STATUS, payload);
	},

	[FETCH_TEMP_CAR_LIST](context) {
		context.commit(FETCH_TEMP_CAR_LIST);
	},

	[FETCH_PRESET](context) {
		context.commit(FETCH_STATUS, true);

		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getPreset)
				.then((response) => {
					context.commit(FETCH_PRESET, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(FETCH_STATUS, false);
				});
		});
	},

	[FETCH_CAR_LIST](context, payload) {
		context.commit(FETCH_STATUS, true);
		return new Promise((resolve, reject) => {
			const request = {
				...context.getters.filterPayload,
				sortType: context.getters.selectedSortField,
			};

			ApiService.post(endPoints.getList, request)
				.then((response) => {
					//  this is for mobile, we need to save the cars to a  temporary list for later use
					//  in mobile filter panel and then we can use the list to filter the cars in the list
					if (payload.isMobileRequest && !payload.init && !context.getters.isImmediatelyFilter) {
						context.commit(SET_CAR_MOBILE_TMP_LIST, response);
					} else context.commit(FETCH_CAR_LIST, response);

					if (context.getters.readyForRequest) context.commit(UPDATE_FILTER_PRESET, response.filterPreset);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(FETCH_STATUS, false);
					context.commit(RESET_IMMEDIATELY_FILTER);
				});
		});
	},

	[FETCH_POPULAR_CAR_LIST](context) {
		context.commit(FETCH_STATUS, true);
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getPopularList)
				.then((response) => {
					context.commit(FETCH_POPULAR_CAR_LIST, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(FETCH_STATUS, false);
				});
		});
	},

	[GET_CAR_BY_ID](context, payload) {
		context.commit(FETCH_STATUS, true);
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getDetail, payload)
				.then((response) => {
					const data = {
						...response,
						photos:
							response.photos.length > 0
								? response.photos.map((y) => y)
								: [`${Vue.prototype.$assetPath}photo-not-ready.svg`],
					};
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					context.commit(FETCH_STATUS, false);
				});
		});
	},

	[TOGGLE_FAVORITE_CAR](context, carId) {
		const hasFavorite = context.getters.favoriteCars.some((id) => id === carId);

		const endPoint = hasFavorite ? endPoints.removeFavorite : endPoints.addFavorite;

		return new Promise((resolve, reject) => {
			ApiService.post(endPoint, carId)
				.then(() => {
					context.commit(TOGGLE_FAVORITE_CAR, { hasFavorite, carId });
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// backoffice

	[BO_FETCH_GRID_DATA](context, payload) {
		ApiService.post(endPoints.getGridData, payload).then((response) => {
			context.commit(BO_FETCH_GRID_DATA, response);
		});
	},

	[BO_REMOVE_LISTED](context, payload) {
		ApiService.post(endPoints.removeListed, payload.id).then(() => {
			context.commit(BO_REMOVE_LISTED, payload);
		});
	},

	[RESERVE_CAR](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.reserve, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[PAYMENT_RESERVATION_CAR](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.paymentReservation, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[VALID_SMS_CODE](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.validSmsCode, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[RESEND_RESERVATION_VALIDATION_CODE](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.resendReservationValidateCode, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[BO_RESERVATION_FETCH_GRID_DATA](context, payload) {
		ApiService.post(endPoints.getReserveGridData, payload).then((response) => {
			context.commit(BO_RESERVATION_FETCH_GRID_DATA, response);
		});
	},
};

const mutations = {
	[SET_DISPLAY_TYPE](state, displayType) {
		state.title.displayType = displayType;
	},

	[SORT_LIST](state, payload) {
		state.filter.payload = { ...state.filter.payload, pageNumber: 1 };
		state.title.selectedSortField = payload.sortType;
		state.immediately = true;
	},

	[TOGGLE_FAVORITE_CAR](state, payload) {
		if (payload.hasFavorite) state.favoriteCars.splice(state.favoriteCars.indexOf(payload.carId), 1);
		else state.favoriteCars.push(payload.carId);
	},

	[SET_REQUEST_READY_STATUS](state, status) {
		state.filter.readyForRequest = status;
	},

	[APPLY_FILTER](state, payload) {
		state.filter.payload = { ...payload.filter, pageNumber: 1 };
	},

	[CLEAR_FILTER](state) {
		state.filter.payload = getDefaultState().filter.payload;
	},

	[GET_MORE_CARS](state) {
		state.immediately = true;
		const pageNumber = state.filter.payload.pageNumber + 1;
		state.filter.payload.pageNumber = pageNumber;
	},

	[UPDATE_FILTER_PRESET](state, filterPreset) {
		state.filter.preset = filterPreset;
		state.filter.readyForRequest = false;
	},

	[FETCH_STATUS](state, status) {
		state.fetching = status;
	},

	[RESET_IMMEDIATELY_FILTER](state) {
		state.immediately = false;
	},

	[SET_CAR_MOBILE_TMP_LIST](state, { carList, totalRecordCount }) {
		state.carsForMobileTmp = carList;
		state.forMobileTmpTotalCount = totalRecordCount;
	},

	[FETCH_TEMP_CAR_LIST](state) {
		this.commit(FETCH_CAR_LIST, {
			carList: state.carsForMobileTmp,
			totalRecordCount: state.forMobileTmpTotalCount,
		});
	},

	[FETCH_PRESET](state, preset) {
		if (!state.filter.preset) {
			state.filter.preset = {};
		}

		state.filter.preset = {
			...state.filter.preset,
			brands: preset?.brands || [],
			cities: preset?.cities || [],
			locations: preset?.locations || [],
		};
	},

	[FETCH_CAR_LIST](state, { carList, totalRecordCount }) {
		if (state.filter.payload.pageNumber === 1) state.cars = [];
		state.cars.push(...carList);
		state.totalCount = totalRecordCount;
	},

	[FETCH_POPULAR_CAR_LIST](state, response) {
		state.popularCars = response;
	},

	// backoffice
	[BO_FETCH_GRID_DATA](state, response) {
		state.backoffice.carList.data = response.data;
		state.backoffice.carList.count = response.totalRecordCount;
	},
	[BO_REMOVE_LISTED](state, car) {
		const index = state.backoffice.carList.data.indexOf(car);
		if (index > -1) {
			state.backoffice.carList.data.splice(index, 1);
			state.backoffice.carList.count -= 1;
		}
	},
	[BO_RESERVATION_FETCH_GRID_DATA](state, response) {
		state.backoffice.reserveCarList.data = response.data;
		state.backoffice.reserveCarList.count = response.totalRecordCount;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
