import store from '@/store/index';

export const permissionGroups = {
	ReportingNewsCrude: 'ReportingNewsCrude',
	CarInfo: 'CarInfo',
	CompanyEdit: 'CompanyEdit',
	Listing: 'Listing',
	FaqEdit: 'FaqEdit',
	ListingContact: 'ListingContact',
};

export const permissionMethods = {
	GetGridData: 'GetGridData',
	GetPreset: 'GetPreset',
	GetActiveGridData: 'GetActiveGridData',
	GetCompletedGridData: 'GetCompletedGridData',
	GetCancelledGridData: 'GetCancelledGridData',
};
export const checkPermission = ({ groupName, methodName }) => {
	if (groupName && store.getters.permissions) {
		const group = groupName ? groupName.toLowerCase() : null;
		const method = methodName ? methodName.toLowerCase() : null;
		return store.getters.permissions.some(
			(x) =>
				(group !== null ? x.group.toLowerCase() === group : false) &&
				(method !== null ? x.name.toLowerCase() === method : true),
		);
	}
	return false;
};

const boMenuItems = [
	{
		action: 'fab fa-microsoft',
		title: 'Müşteri Talepleri',
		active: false,
		open: true,
		subMenuItems: [
			{
				action: 'fas fa-cog',
				title: 'Aktif',
				route: 'requests',
				groupName: permissionGroups.CarInfo,
				viewMethodName: permissionMethods.GetActiveGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Tamamlanan',
				route: 'completedRequest',
				groupName: permissionGroups.CarInfo,
				viewMethodName: permissionMethods.GetCompletedGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'İptal Edilen',
				route: 'cancelledRequest',
				groupName: permissionGroups.CarInfo,
				viewMethodName: permissionMethods.GetCancelledGridData,
				active: false,
			},
		],
	},
	{
		action: 'fa-solid fa-phone-slash',
		title: 'Kara Liste',
		active: false,
		open: true,
		subMenuItems: [
			{
				action: 'fas fa-cog',
				title: 'Kara Liste',
				route: 'blackListEdit',
				groupName: permissionGroups.CompanyEdit,
				active: false,
			},
		],
	},
	{
		action: 'fab fa-microsoft',
		title: 'Listing',
		active: false,
		open: true,
		subMenuItems: [
			{
				action: 'fas fa-cog',
				title: 'Araç Listesi',
				route: 'carList',
				groupName: permissionGroups.Listing,
				viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Mesajlar',
				route: 'messages',
				groupName: permissionGroups.ListingContact,
				viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Opsiyonlu Araç Listesi',
				route: 'reserveCarList',
				groupName: permissionGroups.ListingContact,
				viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
		],
	},
	{
		action: 'fas fa-cog',
		title: 'Genel Ayarlar',
		active: false,
		open: true,
		subMenuItems: [
			{
				action: 'fas fa-cog',
				title: 'Firma Bilgileri',
				route: 'companyEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Çalışma Saatleri',
				route: 'workHoursEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Site Ayarları',
				route: 'siteEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Tema Ayarları',
				route: 'themeEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Dış Bağlantı Ayarları',
				route: 'connectionEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Sosyal Medya',
				route: 'socialMediaEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Sıkça Sorulan Sorular',
				route: 'faqEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Süreçler',
				route: 'businessStepEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Linkler',
				route: 'quickLinkEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Sihirbaz Ayarları',
				route: 'wizardEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Kredi Bilgileri',
				route: 'creditEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Araç Opsiyonlama',
				route: 'carReservationStepEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
			{
				action: 'fas fa-cog',
				title: 'Lokasyonlar',
				route: 'companyLocationEdit',
				groupName: permissionGroups.CompanyEdit,
				// viewMethodName: permissionMethods.GetGridData,
				active: false,
			},
		],
	},
];

export const menuItems = [...boMenuItems];

export default {
	permissionGroups,
	permissionMethods,
	checkPermission,
	menuItems,
};
