import Vue from 'vue';
import ApiService from '@/common/api.service';

export const SET_ROUTE = 'setCurrentRoute';
export const SHOW_NOTIFICATION = 'showNotification';
export const CLEAR_NEW_REQUEST_NOTIFICATION = 'clearNewRequestDate';

const endPoints = {
	getLatestRequest: 'car/getlatestrequest',
};

const state = {
	route: null,
	requestNotification: {
		latestRequestDate: null,
		newRequestDate: null,
	},
	loading: false,
};

const getters = {
	currentRoute: (state) => state.route,
	showNewRequesNotification: (state) =>
		state.requestNotification.latestRequestDate !== null &&
		state.requestNotification.newRequestDate !== null &&
		Vue.prototype
			.$moment(state.requestNotification.newRequestDate)
			.isAfter(state.requestNotification.latestRequestDate),
	isListingApp: (state) => state.route.meta.app === 'listing',
	isLoading: (state) => state.loading,
};

const actions = {
	[SET_ROUTE](context, payload) {
		context.commit(SET_ROUTE, payload);
	},
	[SHOW_NOTIFICATION](context) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getLatestRequest)
				.then((response) => {
					context.commit(SHOW_NOTIFICATION, response);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[CLEAR_NEW_REQUEST_NOTIFICATION](context) {
		context.commit(CLEAR_NEW_REQUEST_NOTIFICATION);
	},
	setLoading(state, isLoading) {
		state.loading = isLoading;
	},
};

const mutations = {
	[SET_ROUTE](state, route) {
		state.route = route;
	},
	[SHOW_NOTIFICATION](state, date) {
		if (state.requestNotification.latestRequestDate == null)
			state.requestNotification.latestRequestDate = date || Vue.prototype.$moment('0001-01-01').toDate();

		if (Vue.prototype.$moment(date).isSameOrAfter(state.requestNotification.latestRequestDate)) {
			state.requestNotification.newRequestDate = date;
		}
	},
	[CLEAR_NEW_REQUEST_NOTIFICATION](state) {
		state.requestNotification.latestRequestDate = state.requestNotification.newRequestDate;
		state.requestNotification.newRequestDate = null;
	},
};

export default {
	// namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
