import ApiService from '@/common/api.service';
import { wizardStep } from '@/common/enums';

export const CHANGE_WIZARD_STEP = 'changeWizardStep';

export const SET_CITY_LIST = 'setCityList';

export const SET_LOCATION_LIST = 'setLocationList';

export const SET_CAR_WIZARD = 'setCarWizard';
export const GET_WIZARD_YEARS = 'getWizardYears';
export const GET_WIZARD_DATA = 'getWizardData';

export const CREATE_CAR_WIZARD = 'createCarWizard';

export const SAVE_CAR_DAMAGE = 'saveCarDamage';
export const SAVE_CONTACT = 'saveContact';

export const APPROVE_VALIDATON = 'approveValidation';
export const RESEND_SMS_CODE = 'reSendSMSCode';
export const SET_CODE_EXPIRE_MINUTE = 'setCodeExpireMinute';

export const CALCULATE_PRICE = 'calculatePrice';
export const SET_TRACKING_NUMBER = 'setTrackingNumber';

export const RESET_STATE = 'resetState';
export const RETURN_WIZARD_START = 'returnWizardStart';

export const FETCH_COLORS = 'getColors';

const endPoints = {
	getCities: 'general/getcitylist',
	getYears: 'carwizard/getyears',
	getCardata: 'carwizard/getcardata',
	createCarInfo: 'carwizard/createcarinfo',
	updateCarDamage: 'carwizard/updatecardamage',
	updateCarContact: 'carwizard/updatecarcontact',
	approveValidationCode: 'carwizard/approvevalidationcode',
	sendValidationCode: 'carwizard/sendvalidationcode',
	calculatePrice: 'carwizard/calculateprice',
	getTrackingNumber: 'carwizard/gettrackingcode',
	getColors: 'car/getcolorlist',
	getLocationList: 'company/getlocationlist',
};

const getDefaultState = () => ({
	preset: {
		years: [],
		brands: [],
		models: [],
		bodyTypes: [],
		transmissionTypes: [],
		fuelTypes: [],
		versions: [],
		colors: [],
		cities: [],
		locations: [],
	},
	wizard: {
		step: 1,
		smsCodeExpireMinute: null,
		carInfo: {
			year: null,
			brand: null,
			model: null,
			bodyType: null,
			transmissionType: null,
			fuelType: null,
			version: null,
			kilometers: null,
			color: null,
		},
		damage: null,
		contact: null,
		price: null,
		carImage: null,
		trackingNumber: null,
		locations: [],
		isLocation: null,
	},
});

const state = getDefaultState();

const getters = {
	getYears: (state) => state.preset.years.map((x) => ({ id: x, value: x })).sort((a, b) => b.id - a.id),
	getBrands: (state) => state.preset.brands.map((x) => ({ id: x.id, value: x.name })),
	getModels: (state) => state.preset.models.map((x) => ({ id: x.id, value: x.name })),
	getBodyTypes: (state) => state.preset.bodyTypes.map((x) => ({ id: x.id, value: x.name })),
	getTransmissionTypes: (state) => state.preset.transmissionTypes.map((x) => ({ id: x.id, value: x.name })),
	getFuelTypes: (state) => state.preset.fuelTypes.map((x) => ({ id: x.id, value: x.name })),
	getVersions: (state) => state.preset.versions.map((x) => ({ id: x.id, value: x.name })),
	getColors: (state) => state.preset.colors.map((x) => ({ id: x.id, value: x.name })),
	getWizardCarInfo: (state) => state.wizard.carInfo,
	getWizardStep: (state) => state.wizard.step,
	getCarDamages: (state) => state.wizard.damage,
	getPrice: (state) => state.wizard.price,
	getCarImg: (state) => state.wizard.carImage,
	getSmsCodeExpireMinute: (state) => state.wizard.smsCodeExpireMinute,
	getTrackingNumber: (state) => state.wizard.trackingNumber,
	getCities: (state) => state.preset.cities,
	getLocations: (state) => state.wizard.locations,
	getIsLocation: (state) => state.wizard.isLocation,
	getLocationList: (state) => state.preset.locations,
};

const actions = {
	[SET_CAR_WIZARD](context, payload) {
		return new Promise((resolve) => {
			context.commit(SET_CAR_WIZARD, payload);
			resolve();
		});
	},
	[GET_WIZARD_YEARS](context) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getYears)
				.then((response) => {
					context.commit(GET_WIZARD_YEARS, response);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[GET_WIZARD_DATA](context, { payload, fillArrayField }) {
		return new Promise((resolve, reject) => {
			if (payload !== null) {
				ApiService.post(endPoints.getCardata, payload)
					.then((response) => {
						context.commit(GET_WIZARD_DATA, { response, fillArrayField });
						resolve();
					})
					.catch((error) => {
						reject(error);
					});
			} else context.commit(GET_WIZARD_DATA, { response: payload, fillArrayField });
		});
	},
	[CREATE_CAR_WIZARD](context, payload) {
		const { carInfo } = payload;
		const newCarInfo = {
			year: carInfo.year,
			brand: carInfo.brand,
			model: carInfo.model,
			bodyType: carInfo.bodyType,
			transmissionType: carInfo.transmissionType,
			fuelType: carInfo.fuelType,
			version: carInfo.version,
			kilometers: carInfo.kilometers,
			color: carInfo.color,
			key: carInfo.key,
			isExchangeRequest: carInfo.isExchangeRequest,
			exchangeDescription: carInfo.exchangeDescription,
		};

		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.createCarInfo, newCarInfo)
				.then((key) => {
					const payloadWithKey = { ...payload };
					payloadWithKey.carInfo.key = key;
					context.commit(SET_CAR_WIZARD, payloadWithKey);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[SAVE_CAR_DAMAGE](context, payload) {
		const newDamage = { ...payload, key: context.state.wizard.carInfo.key };
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateCarDamage, newDamage)
				.then(() => {
					context.commit(SAVE_CAR_DAMAGE, newDamage);
					context.commit(CHANGE_WIZARD_STEP, wizardStep.CONTACT);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[SAVE_CONTACT](context, payload) {
		const newContact = { ...payload, phoneNumber: `90${payload.phoneNumber}`, key: context.state.wizard.carInfo.key };
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateCarContact, newContact)
				.then((response) => {
					context.commit(SAVE_CONTACT, newContact);
					context.commit(SET_CODE_EXPIRE_MINUTE, response);
					context.commit(CHANGE_WIZARD_STEP, wizardStep.SMS_APPROVE);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[CHANGE_WIZARD_STEP](context, payload) {
		context.commit(CHANGE_WIZARD_STEP, payload);
	},
	[SET_CITY_LIST](context) {
		ApiService.post(endPoints.getCities).then((response) => {
			context.commit(SET_CITY_LIST, response);
		});
	},
	[SET_LOCATION_LIST](context) {
		ApiService.post(endPoints.getLocationList).then((response) => {
			context.commit(SET_LOCATION_LIST, response);
		});
	},
	[APPROVE_VALIDATON](context, payload) {
		const newApprove = { smsCode: payload, key: context.state.wizard.carInfo.key };
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.approveValidationCode, newApprove)
				.then(async () => {
					context.commit(CHANGE_WIZARD_STEP, wizardStep.OFFER);
					resolve(true);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[RESEND_SMS_CODE](context) {
		context.commit(SET_CODE_EXPIRE_MINUTE, null);
		const requestPay = { key: context.state.wizard.carInfo.key };
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.sendValidationCode, requestPay)
				.then((response) => {
					context.commit(SET_CODE_EXPIRE_MINUTE, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[CALCULATE_PRICE](context) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.calculatePrice, context.state.wizard.carInfo.key)
				.then((response) => {
					setTimeout(() => {
						context.commit(CALCULATE_PRICE, response);
						resolve(response);
					}, 3000);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[SET_TRACKING_NUMBER](context, payload) {
		const newPayload = {
			key: context.state.wizard.carInfo.key,
			locationId: payload.locationId,
		};
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getTrackingNumber, newPayload)
				.then((response) => {
					context.commit(SET_TRACKING_NUMBER, response.trackingNumber);
					context.commit(CHANGE_WIZARD_STEP, wizardStep.COMPLETE);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[RESET_STATE](context) {
		return new Promise((resolve) => {
			context.commit(RESET_STATE);
			resolve();
		});
	},
	[RETURN_WIZARD_START](context) {
		return new Promise((resolve) => {
			context.commit(RETURN_WIZARD_START);
			resolve();
		});
	},
	[FETCH_COLORS](context) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getColors)
				.then((response) => {
					context.commit(FETCH_COLORS, response);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

const mutations = {
	[SET_CITY_LIST](state, cities) {
		state.preset.cities = cities;
	},
	[SET_LOCATION_LIST](state, locations) {
		state.preset.locations = locations;
	},
	[SET_CAR_WIZARD](state, { carInfo, step }) {
		state.wizard.carInfo = carInfo;
		state.wizard.step = step;
	},
	[GET_WIZARD_YEARS](state, response) {
		state.preset.years = response;
	},
	[GET_WIZARD_DATA](state, { response, fillArrayField }) {
		state.preset[fillArrayField] = response || [];

		if (fillArrayField === 'models') {
			state.preset.bodyTypes = [];
			state.preset.transmissionTypes = [];
			state.preset.fuelTypes = [];
			state.preset.versions = [];
		} else if (fillArrayField === 'bodyTypes') {
			state.preset.transmissionTypes = [];
			state.preset.fuelTypes = [];
			state.preset.versions = [];
		} else if (fillArrayField === 'transmissionTypes') {
			state.preset.fuelTypes = [];
			state.preset.versions = [];
		} else if (fillArrayField === 'fuelTypes') {
			state.preset.versions = [];
		}
	},
	[CHANGE_WIZARD_STEP](state, step) {
		state.wizard.step = step;
	},
	[SAVE_CAR_DAMAGE](state, damage) {
		state.wizard.damage = damage;
	},
	[SAVE_CONTACT](state, contact) {
		state.wizard.contact = contact;
	},
	[SET_CODE_EXPIRE_MINUTE](state, minute) {
		state.wizard.smsCodeExpireMinute = minute;
	},
	[CALCULATE_PRICE](state, response) {
		state.wizard.price = response.price;
		state.wizard.carImage = response.imageUrl;
		state.wizard.locations = response.locations;
		state.wizard.isLocation = response.isLocation;
	},
	[SET_TRACKING_NUMBER](state, trackingNumber) {
		state.wizard.trackingNumber = trackingNumber;
	},
	[RESET_STATE](state) {
		Object.assign(state, getDefaultState());
	},
	[RETURN_WIZARD_START](state) {
		const newState = { ...getDefaultState(), preset: state.preset };
		Object.assign(state, newState);
	},
	[FETCH_COLORS](state, colors) {
		state.preset.colors = colors;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
