import ApiService from '@/common/api.service';
import { sellStep, fileType, roleType } from '@/common/enums';

export const FETCH_TOTAL_COUNT = 'fetchTotalCount';
export const FETCH_GRID_DATA = 'fetchGridData';
export const EXPORT_GRID_DATA = 'exportGridData';
export const FETCH_SUMMARY = 'fetchSummary';
export const FETCH_GRID_FILTER_PRESET = 'fetchGridFilterPreset';

export const FETCH_CAR_PRESET = 'fetchCarPreset';
export const FETCH_COMMENTS = 'fetchComments';
export const FETCH_RELATED_CARS = 'fetchRelatedCars';
export const FETCH_FILES = 'fetchFiles';
export const FETCH_CANCEL_STATUS = 'fetchCancelStatus';

export const SET_SUPERVISOR = 'setSupervisor';
export const ASSIGN_TO_SUPERVISOR = 'assignToSupervisor';

export const UPDATE_PRICE = 'updatePrice';
export const UPDATE_CAR_DETAIL = 'updatecardetail';
export const UPDATE_CAR_INFO = 'updateCarInfo';

export const CREATE_APPOINTMENT = 'createAppointment';
export const SAVE_COMMENT = 'saveComment';

export const UPLOAD_MULTIPLE_FILES = 'uploadMultipleFiles';
export const DELETE_FILE_SERVER = 'deleteFileServer';
export const CLEAR_MUST_FILE = 'clearMustFile';

export const SET_EXPERTISE = 'setExpertise';
export const SET_PAYMENT = 'setPayment';
export const SET_COMPLETED = 'setCompleted';
export const SET_CANCELLED = 'setCancelled';
export const SET_PRE_EXAMINATION = 'setpreexamination';

export const CHANGE_STEP = 'changeStep';

export const FETCH_TRAMERS = 'fetchTramers';
export const FETCH_TRAMER_DETAIL = 'fetchTramerDetail';

export const FETCH_PRICE_HISTORY = 'fetchPriceHistory';

export const DISPOSE_TRAMERS = 'disposeTramers';
export const DISPOSE_TRAMER_DETAIL = 'disposeTramerDetail';

export const SET_FILTER_TMP_PAYLOAD = 'setFilterTmpPayload';
export const CLEAR_FILTER = 'clearFilter';

export const SET_FILTER_ACTIVE = 'setFilterActive';

const endPoints = {
	getGridData: 'car/getgriddata',
	getGridExportData: 'car/getgridexportdata',
	getGridDataCount: 'car/getgriddatacount',
	getGridFilterPreset: 'car/getgridfilterpreset',
	setSupervisor: 'car/setsupervisor',
	updatePrice: 'car/updateprice',
	updateCarDetail: 'car/updateCarDetail',
	updateCarInfo: 'car/updatecar',
	setScheduled: 'car/setscheduled',
	getPreset: 'car/getpreset',
	getCommentList: 'car/getcommentlist',
	addComment: 'car/addcomment',
	addFiles: 'car/addfiles',
	deleteFile: 'car/deletefile',
	setExpertise: 'car/setexpertise',
	getSummary: 'car/getsummary',
	setPayment: 'car/setpayment',
	setCompleted: 'car/setcompleted',
	setCancelled: 'car/setcancelled',
	getTramers: 'car/gettramerinfo',
	getTramerDetail: 'car/gettramerdetail',
	getcarpricehistory: 'car/getcarpricehistory',
	assignToSupervisor: 'car/assigntosupervisor',
	setPreExamination: 'car/setpreexamination',
	getRelatedCarInfo: 'car/getrelatedcarinfo',
};

const getDefaultState = () => ({
	data: [],
	totalCount: null,
	summary: null,
	gridPreset: null,
	carPreset: null,
	comments: [],
	files: [],
	cancelStatus: [],
	tramers: [],
	tramerDetail: null,
	priceHistory: [],
	filterApplied: false,
	relatedCars: [],
});

const state = {
	...getDefaultState(),
	filterTmpPayload: null,
};

const getters = {
	carInfoGridData: (state) => state.data,
	carInfoTotalCount: (state) => state.totalCount,
	carInfoSummary: (state) => state.summary,
	gridPreset: (state) => state.gridPreset,
	carSellStep: (state) => state.sellStep,
	carPreset: (state) => state.carPreset,
	comments: (state) => state.comments,
	files: (state) => state.files,
	cancelStatus: (state) => state.cancelStatus,
	tramers: (state) => state.tramers,
	isAuthUser: (state, getters) =>
		state.carPreset?.carUseId === getters.currentUser?.userId ||
		getters.currentUser?.roleId === roleType.SUPER_ADMIN ||
		getters.currentUser?.roleId === roleType.COMPANY_ADMIN,
	tramerDetails: (state) => state.tramerDetail,
	priceHistory: (state) => state.priceHistory,
	supervisorId: (state) => state.carPreset?.carUseId || null,
	filterTmpPayload: (state) => state.filterTmpPayload,
	isFilterApplied: (state) => state.filterApplied,
	relatedCars: (state) => state.relatedCars,
};

const actions = {
	[FETCH_RELATED_CARS](context, payload) {
		return new Promise((resolve, reject) => {
			const requestPayload = { key: payload.carId, phone: payload.cicContactPhone };

			ApiService.post(endPoints.getRelatedCarInfo, requestPayload)
				.then((response) => {
					context.commit(FETCH_RELATED_CARS, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[FETCH_PRICE_HISTORY](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getcarpricehistory, payload)
				.then((response) => {
					context.commit(FETCH_PRICE_HISTORY, response);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[FETCH_TRAMERS](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getTramers, payload)
				.then((response) => {
					if (response.status_code.toLowerCase() === 'success') {
						context.commit(FETCH_TRAMERS, response.content.damage);
						context.commit(SAVE_COMMENT, response.comment);
						resolve();
					} else {
						reject(
							new Error(response.status_code, {
								code: response.status_code,
								cause: { code: response.status_desc, message: response.status_desc },
							}),
						);
					}
				})
				.catch((error) => {
					const response = JSON.parse(error.responseText).data;
					reject(
						new Error(response.status_code, {
							code: response.status_code,
							cause: { code: response.status_desc, message: response.status_desc },
						}),
					);
				});
		});
	},
	[FETCH_TRAMER_DETAIL](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getTramerDetail, payload)
				.then((response) => {
					if (response.status_code.toLowerCase() === 'success') {
						if (response.content?.length > 0) {
							context.commit(FETCH_TRAMER_DETAIL, response.content[0]);
						}
						resolve();
					} else {
						reject(
							new Error(response.status_code, {
								code: response.status_code,
								cause: { code: response.status_desc, message: response.status_desc },
							}),
						);
					}
				})
				.catch((error) => {
					const response = JSON.parse(error.responseText).data;
					reject(
						new Error(response.status_code, {
							code: response.status_code,
							cause: { code: response.status_desc, message: response.status_desc },
						}),
					);
				});
		});
	},
	[DISPOSE_TRAMERS](context) {
		context.commit(DISPOSE_TRAMERS);
	},
	[DISPOSE_TRAMER_DETAIL](context) {
		context.commit(DISPOSE_TRAMER_DETAIL);
	},
	[SET_FILTER_ACTIVE](context, payload) {
		context.commit(SET_FILTER_ACTIVE, payload);
	},
	[FETCH_GRID_DATA](context, { endpoint, payload }) {
		context.commit(SET_FILTER_TMP_PAYLOAD, payload);

		ApiService.post(endpoint, payload).then((response) => {
			context.commit(FETCH_GRID_DATA, response.data);
			context.commit(FETCH_TOTAL_COUNT, response.totalRecordCount);
		});
	},
	[EXPORT_GRID_DATA](context, { endpoint, payload }) {
		return new Promise((resolve, reject) => {
			ApiService.post(endpoint, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[FETCH_SUMMARY](context) {
		ApiService.post(endPoints.getSummary).then((response) => {
			context.commit(FETCH_SUMMARY, response);
		});
	},
	[CLEAR_FILTER](context) {
		return new Promise((resolve) => {
			context.commit(CLEAR_FILTER);
			resolve();
		});
	},
	[FETCH_GRID_FILTER_PRESET](context) {
		ApiService.post(endPoints.getGridFilterPreset).then((response) => {
			context.commit(FETCH_GRID_FILTER_PRESET, response);
		});
	},
	[SET_SUPERVISOR](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.setSupervisor, payload).then(() => {
				resolve();
			});
		});
	},
	[ASSIGN_TO_SUPERVISOR](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.assignToSupervisor, payload).then(() => {
				context.commit(ASSIGN_TO_SUPERVISOR, payload);
				resolve();
			});
		});
	},
	[UPDATE_PRICE](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.updatePrice, payload).then((response) => {
				context.commit(UPDATE_PRICE, payload.price);
				context.commit(SAVE_COMMENT, response);
				resolve();
			});
		});
	},
	[UPDATE_CAR_INFO](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.updateCarInfo, payload).then((response) => {
				context.commit(SAVE_COMMENT, response);
				resolve();
			});
		});
	},
	[UPDATE_CAR_DETAIL](context, payload) {
		return new Promise((resolve) => {
			const newPayload = {
				...payload,
				phoneNumber: `90${payload.phoneNumber}`,
				ownerPhone: payload.ownerPhone ? `90${payload.ownerPhone}` : null,
				ownerWorkPhone: payload.ownerWorkPhone ? `90${payload.ownerWorkPhone}` : null,
			};
			ApiService.post(endPoints.updateCarDetail, newPayload).then((response) => {
				context.commit(UPDATE_CAR_DETAIL, payload);
				context.commit(SAVE_COMMENT, response);
				resolve();
			});
		});
	},
	[FETCH_CAR_PRESET](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getPreset, { id: payload })
				.then((response) => {
					context.commit(FETCH_CAR_PRESET, response.carInfo);
					context.commit(FETCH_COMMENTS, response.comments);
					context.commit(FETCH_FILES, response.files);
					context.commit(FETCH_CANCEL_STATUS, response.cancelStatus);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[FETCH_COMMENTS](context, payload) {
		ApiService.post(endPoints.getCommentList, payload).then((response) => {
			context.commit(FETCH_COMMENTS, response);
		});
	},
	[CREATE_APPOINTMENT](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.setScheduled, payload)
				.then((response) => {
					context.commit(CHANGE_STEP, sellStep.SCHEDULED);
					context.commit(ASSIGN_TO_SUPERVISOR, payload);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[SAVE_COMMENT](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.addComment, payload).then((response) => {
				context.commit(SAVE_COMMENT, response);
				resolve();
			});
		});
	},
	[UPLOAD_MULTIPLE_FILES](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.addFiles, payload)
				.then((response) => {
					context.commit(CLEAR_MUST_FILE, payload);
					context.commit(UPLOAD_MULTIPLE_FILES, response);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[DELETE_FILE_SERVER](context, payload) {
		const newPayload = { key: payload.key, fileId: payload.data.fileId };
		ApiService.post(endPoints.deleteFile, newPayload).then(() => {
			context.commit(DELETE_FILE_SERVER, payload.data);
		});
	},
	[SET_EXPERTISE](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.setExpertise, payload).then(() => {
				context.commit(CHANGE_STEP, sellStep.EXPERTISE);
				resolve();
			});
		});
	},
	[SET_PAYMENT](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.setPayment, payload).then(() => {
				context.commit(CHANGE_STEP, sellStep.PAYMENT);
				resolve();
			});
		});
	},
	[SET_COMPLETED](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.setCompleted, payload).then(() => {
				context.commit(CHANGE_STEP, sellStep.COMPLETED);
				resolve();
			});
		});
	},
	[SET_CANCELLED](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.setCancelled, payload).then(() => {
				context.commit(CHANGE_STEP, sellStep.CANCELLED);
				resolve();
			});
		});
	},
	[SET_PRE_EXAMINATION](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.setPreExamination, payload).then(() => {
				context.commit(CHANGE_STEP, sellStep.PRE_EXAMINATION);
				resolve();
			});
		});
	},
};

const mutations = {
	[FETCH_RELATED_CARS](state, relatedCars) {
		state.relatedCars = relatedCars;
	},
	[CLEAR_FILTER](state) {
		state.filterTmpPayload = null;
	},
	[SET_FILTER_TMP_PAYLOAD](state, payload) {
		if (payload?.isClear) {
			state.filterTmpPayload = null;
		} else {
			state.filterTmpPayload = payload;
		}
	},
	[ASSIGN_TO_SUPERVISOR](state, payload) {
		state.carPreset.carUseId = payload.userId;
	},
	[FETCH_PRICE_HISTORY](state, prices) {
		state.priceHistory = prices;
	},
	[FETCH_TRAMERS](state, tramers) {
		state.tramers = tramers;
	},
	[FETCH_TRAMER_DETAIL](state, tramerDetail) {
		state.tramerDetail = tramerDetail;
	},
	[DISPOSE_TRAMERS](state) {
		state.tramers = [];
		state.tramerDetail = null;
	},
	[DISPOSE_TRAMER_DETAIL](state) {
		state.tramerDetail = null;
	},
	[SET_FILTER_ACTIVE](state, payload) {
		state.filterApplied = payload;
	},
	[FETCH_GRID_DATA](state, response) {
		state.data = response;
		state.carPreset = null;
	},
	[FETCH_TOTAL_COUNT](state, count) {
		state.totalCount = count;
	},
	[FETCH_SUMMARY](state, response) {
		state.summary = response;
	},
	[FETCH_CAR_PRESET](state, response) {
		state.carPreset = response;
	},
	[FETCH_GRID_FILTER_PRESET](state, response) {
		state.gridPreset = response;
	},
	[FETCH_COMMENTS](state, response) {
		state.comments = response;
	},
	[FETCH_FILES](state, response) {
		state.files = response;
	},
	[FETCH_CANCEL_STATUS](state, response) {
		state.cancelStatus = response;
	},
	[SAVE_COMMENT](state, comment) {
		state.comments.splice(0, 0, comment);
	},
	[UPDATE_PRICE](state, price) {
		state.carPreset.carGalleryPrice = price;
	},
	[UPDATE_CAR_DETAIL](state, contact) {
		state.carPreset.phoneNumber = contact.phoneNumber;
		state.carPreset.email = contact.email;
		state.carPreset.name = contact.name;
		state.carPreset.citId = contact.citId;
		state.carPreset.phoneNumberOther = contact.phoneOther;
		state.carPreset.emailOther = contact.emailOther;
		state.carPreset.nameOther = contact.nameOther;
	},
	[CHANGE_STEP](state, step) {
		state.carPreset.carCurrentWzsId = step;
	},
	[UPLOAD_MULTIPLE_FILES](state, files) {
		if (files.length > 0) {
			state.files.push(...files);
		}
	},
	[CLEAR_MUST_FILE](state, response) {
		const clearTypes = [];
		if (response.expertiseFile) clearTypes.push(fileType.EXPERTISE);
		if (response.licenseFile) clearTypes.push(fileType.LICENSE);

		const mustFiles = state.files.filter((x) => clearTypes.some((y) => y === x.typeId));
		if (mustFiles.length > 0) {
			mustFiles.forEach((x) => {
				const index = state.files.indexOf(x);
				if (index > -1) state.files.splice(index, 1);
			});
		}
	},
	[DELETE_FILE_SERVER](state, file) {
		const index = state.files.indexOf(file);
		if (index > -1) {
			state.files.splice(index, 1);
		}
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
