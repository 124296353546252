/* eslint-disable no-shadow */

// action types
export const SET_SIDEBAR = 'setSideBar';
export const SET_THEME = 'setTheme';

// mutation types
const state = {
	isMini: false,
	dark: false,
};

const getters = {
	isMini: (state) => state.isMini,
	isDark: (state) => state.dark,
};

const actions = {
	[SET_SIDEBAR](context) {
		context.commit(SET_SIDEBAR);
	},
	[SET_THEME](context, payload) {
		context.commit(SET_THEME, payload);
	},
};

const mutations = {
	[SET_SIDEBAR](state) {
		state.isMini = !state.isMini;
	},
	[SET_THEME](state, payload) {
		state.dark = payload;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
