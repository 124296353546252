import MessageDialog from '@/components/_shared/MessageDialog.vue';
import SnackBarMessage from '@/components/_shared/SnackBarMessage.vue';
import VueApexCharts from 'vue-apexcharts';

const GlobalComponents = {
	install(Vue) {
		Vue.component(SnackBarMessage.name, SnackBarMessage);
		Vue.component(MessageDialog.name, MessageDialog);
		Vue.component('apexchart', VueApexCharts);
	},
};

export default GlobalComponents;
