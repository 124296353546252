import ApiService from '@/common/api.service';

export const SET_COMPANY_PREVIEW = 'setCompanyPreview';
export const UPLOAD_PREVIEW_IMAGE = 'uploadPreviewImage';

const endPoints = {
	uploadPreviewImage: 'company/uploadpreviewimage',
};

const state = {
	previewSite: null,
};

const getters = {
	getPreviewSite: (state) => state.previewSite,
};

const actions = {
	[SET_COMPANY_PREVIEW](context, payload) {
		return new Promise((resolve) => {
			context.commit(SET_COMPANY_PREVIEW, payload);
			resolve();
		});
	},
	[UPLOAD_PREVIEW_IMAGE](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.uploadPreviewImage, payload).then((response) => {
				resolve(response);
			});
		});
	},
};

const mutations = {
	[SET_COMPANY_PREVIEW](state, response) {
		state.previewSite = response;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
