<template>
	<v-app>
		<v-snackbar v-model="updateExists" :timeout="-1">
			New version available! Click to update
			<template v-slot:action="{ attrs }">
				<v-btn color="green" text v-bind="attrs" @click="refreshApp"> Update </v-btn>
			</template>
		</v-snackbar>
		<nprogress-container />
		<SnackBarMessage />
		<message-dialog />
		<router-view> </router-view>
	</v-app>
</template>
<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'App',
	components: { NprogressContainer },
	data() {
		return {
			autoRefreshAfterToUpgrade: false,
			refreshing: false,
			registration: null,
			updateExists: false,
			snackbar: false,
			loaded: false,
		};
	},
	computed: {
		...mapGetters(['siteStatus', 'insider']),
	},
	mounted() {
		this.$nextTick(() => {
			this.initInsider();
		});
	},
	created() {
		document.addEventListener('swUpdated', this.processRefreshUI, { once: true });
		if (navigator.serviceWorker) {
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (this.refreshing) return;
				this.refreshing = true;
				window.location.reload();
			});
		}
	},
	beforeDestroy() {
		document.removeEventListener('swUpdated', this.processRefreshUI);
	},
	methods: {
		initInsider() {
			if (!this.insider.active) return;

			const externalScript = document.createElement('script');
			externalScript.setAttribute('async', '');
			externalScript.setAttribute(
				'src',
				`https://${this.insider.subDomain}.api.useinsider.com/ins.js?id=${this.insider.id}`,
			);
			document.head.appendChild(externalScript);
		},
		processRefreshUI(e) {
			this.registration = e.detail;

			if (this.autoRefreshAfterToUpgrade) {
				this.refreshApp();
				return;
			}

			this.showRefreshUI();
		},
		showRefreshUI() {
			this.updateExists = true;
		},
		refreshApp() {
			this.updateExists = false;
			if (!this.registration || !this.registration.waiting) return;
			this.registration.waiting.postMessage('skipWaiting');
		},
	},
	watch: {
		$route: {
			handler(value) {
				this.autoRefreshAfterToUpgrade = value.meta.autoRefreshAfterToUpgrade ?? false;
				if (this.autoRefreshAfterToUpgrade) this.refreshApp();
			},
		},
	},
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@100;200;300;400;500;600&display=swap');

html,
input,
select,
.v-label,
.v-input__control {
	font-size: 14px;
}
#app {
	overflow: hidden;
}
* {
	font-family: 'Encode Sans Semi Condensed', sans-serif;
}

.v-application {
	.text-h3,
	.text-h4,
	.text-h5,
	.text-h6,
	.text-subtitle-1,
	.text-subtitle-2,
	.text-body-1,
	.text-body-2 {
		font-family: 'Encode Sans Semi Condensed', sans-serif !important;
	}
}

#nprogress .bar {
	background: rgb(255, 62, 87) !important;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #9e9e9e;
}

::-webkit-scrollbar-thumb {
	background: #6d6d6d;
}

::-webkit-scrollbar-thumb:hover {
	background: rgb(58, 58, 58);
}

.theme--light {
	::-webkit-scrollbar-track {
		background: #e6e6e6;
	}

	::-webkit-scrollbar-thumb {
		background: #b0b0b0;
		border-radius: 7px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: rgb(104, 104, 104);
	}
}
.theme--dark {
	::-webkit-scrollbar-track {
		background: #3a3a3a;
	}

	::-webkit-scrollbar-thumb {
		background: #555555;
		border-radius: 7px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: rgb(78, 78, 78);
	}
}
</style>
