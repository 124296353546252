import ApiService from '@/common/api.service';

export const INIT_DASHBOARD = 'initDashboard';
export const SET_REQUEST_STATUS = 'setRequestStatus';
export const SET_DONUT_CHART_DATA = 'setDonutChartData';
export const SET_CANCELLED_REQUEST_DATA = 'setCancelledRequestData';
export const SET_COMPLETED_REQUEST_DATA = 'setCompletedRequestData';
export const SET_CUSTOMER_RESPONSE_DATA = 'setCustomerResponseData';
export const SET_PROCESS_COMPLETE_DATA = 'setProcessCompleteData';
export const SET_FUNNEL_DATA = 'setFunnelData';

const endPoints = {
	getCarDashboard: 'cardashboard/getcardashboard',
};

const state = () => ({
	baseData: null,
	requestStatusData: null,
	donutChartData: null,
	cancelledRequestData: null,
	completedRequestData: null,
	customerResponseData: null,
	processCompleteData: null,
	funnelChartData: null,
});

const getters = {
	requestStatusData: (state) => state.requestStatusData,
	donutChartData: (state) => state.donutChartData,
	cancelledRequestData: (state) => state.cancelledRequestData,
	completedRequestData: (state) => state.completedRequestData,
	customerResponseData: (state) => state.customerResponseData,
	processCompleteData: (state) => state.processCompleteData,
	funnelChartData: (state) => state.funnelChartData,
};

const actions = {
	[INIT_DASHBOARD](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getCarDashboard, payload)
				.then((data) => {
					context.commit(INIT_DASHBOARD, data);
					context.commit(SET_REQUEST_STATUS, data);
					context.commit(SET_DONUT_CHART_DATA, data);
					context.commit(SET_CANCELLED_REQUEST_DATA, data);
					context.commit(SET_COMPLETED_REQUEST_DATA, data);
					context.commit(SET_CUSTOMER_RESPONSE_DATA, data);
					context.commit(SET_PROCESS_COMPLETE_DATA, data);
					context.commit(SET_FUNNEL_DATA, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

const mutations = {
	[INIT_DASHBOARD](state, data) {
		state.baseData = data;
	},
	[SET_REQUEST_STATUS](state, data) {
		state.requestStatusData = {
			chart: {
				min: data.requestStatusMin,
				max: data.requestStatusMax,
				dates: data.requestStatusByDate.dates,
				exchange: data.requestStatusByDate.exchange,
				purchase: data.requestStatusByDate.purchase,
				total: data.requestStatusByDate.total,
			},
			progress: [...data.requestStatusByStatus],
		};
	},
	[SET_DONUT_CHART_DATA](state, data) {
		state.donutChartData = {
			labels: data.requestStatusByStatusPercentage.map((item) => item.status),
			series: data.requestStatusByStatusPercentage.map((item) => item.percentage),
		};
	},
	[SET_CANCELLED_REQUEST_DATA](state, data) {
		state.cancelledRequestData = {
			count: data.requestStatusCurrentCancelledCount,
			price: data.requestStatusCurrentCancelledPrice,
			prevCount: data.requestStatusPreviousCancelledCount,
			prevPrice: data.requestStatusPreviousCancelledPrice,
		};
	},
	[SET_COMPLETED_REQUEST_DATA](state, data) {
		state.completedRequestData = {
			count: data.requestStatusCurrentCompletedCount,
			price: data.requestStatusCurrentCompletedPrice,
			prevCount: data.requestStatusPreviousCompletedCount,
			prevPrice: data.requestStatusPreviousCompletedPrice,
		};
	},
	[SET_CUSTOMER_RESPONSE_DATA](state, data) {
		state.customerResponseData = {
			average: data.requestStatusCustomerResponseAverageCurrent,
			averagePrev: data.requestStatusCustomerResponseAveragePrevious,
			fast: data.requestStatusCustomerResponseFast,
			lag: data.requestStatusCustomerResponseLag,
			onTime: data.requestStatusCustomerResponseOnTime,
		};
	},
	[SET_PROCESS_COMPLETE_DATA](state, data) {
		state.processCompleteData = {
			average: data.requestStatusProcessCompleteAverageCurrent,
			averagePrev: data.requestStatusProcessCompleteAveragePrevious,
			fast: data.requestStatusProcessCompleteFast,
			lag: data.requestStatusProcessCompleteLag,
			onTime: data.requestStatusProcessCompleteOnTime,
		};
	},
	[SET_FUNNEL_DATA](state, data) {
		state.funnelChartData = {
			labels: data.requestStatusKPI.map((item) => item.status),
			values: data.requestStatusKPI.map((item) => item.count),
		};
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
