import ApiService from '@/common/api.service';

export const SEND_CONTACT_FORM = 'SEND_CONTACT_FORM';

export const FETCH_MESSAGE_GRID_DATA = 'FETCH_MESSAGE_GRID_DATA';
export const DELETE_CONTACT_MESSAGE = 'DELETE_CONTACT_MESSAGE ';
export const CHANGE_CONTACT_MESSAGE_STATUS = 'CHANGE_CONTACT_MESSAGE_STATUS';

const endPoints = {
	create: 'listingcontact/create',
	getGridData: 'listingcontact/getgriddata',
	setStatus: 'listingcontact/setstatus',
	delete: 'listingcontact/delete',
};

const getDefaultState = () => ({
	messageGrid: {
		loading: false,
		data: [],
		count: 0,
	},
});

const state = {
	...getDefaultState(),
};

const getters = {
	messageGrid: (state) => state.messageGrid,
};

const actions = {
	[SEND_CONTACT_FORM](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.create, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {});
		});
	},
	[DELETE_CONTACT_MESSAGE](context, payload) {
		return new Promise((resolve, reject) => {
			const requestPayload = { id: payload.id, listingKey: payload.listingKey };
			ApiService.post(endPoints.delete, requestPayload)
				.then((response) => {
					context.commit(DELETE_CONTACT_MESSAGE, payload);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {});
		});
	},
	[CHANGE_CONTACT_MESSAGE_STATUS](context, payload) {
		return new Promise((resolve, reject) => {
			const newStatus = payload.status.toLowerCase() === 'yeni' ? 2 : 1;
			const newStatusText = payload.status.toLowerCase() === 'yeni' ? 'Cevaplandı' : 'Yeni';

			const requestPayload = {
				id: payload.id,
				listingKey: payload.listingKey,
				status: newStatus,
			};

			ApiService.post(endPoints.setStatus, requestPayload)
				.then((response) => {
					context.commit(CHANGE_CONTACT_MESSAGE_STATUS, {
						...payload,
						status: newStatusText,
					});
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {});
		});
	},
	[FETCH_MESSAGE_GRID_DATA](context, payload) {
		state.messageGrid.loading = true;
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getGridData, payload)
				.then((response) => {
					context.commit(FETCH_MESSAGE_GRID_DATA, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				})
				.finally(() => {
					state.messageGrid.loading = false;
				});
		});
	},
};

const mutations = {
	[FETCH_MESSAGE_GRID_DATA](state, response) {
		state.messageGrid.data = response.data;
		state.messageGrid.count = response.totalRecordCount;
	},
	[DELETE_CONTACT_MESSAGE](state, payload) {
		const index = state.messageGrid.data.indexOf(payload);
		if (index > -1) {
			state.messageGrid.data.splice(index, 1);
			state.messageGrid.count = -1;
		}
	},
	[CHANGE_CONTACT_MESSAGE_STATUS](state, payload) {
		const index = state.messageGrid.data.findIndex((x) => x.id === payload.id);
		if (index > -1) {
			state.messageGrid.data.splice(index, 1, payload);
		}
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
