import '@fortawesome/fontawesome-free/css/all.css';
import minifyTheme from 'minify-css-string';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import { en, tr } from 'vuetify/lib/locale';

Vue.use(Vuetify);

export default function createVuetify(settings) {
	const colors = {
		'template-color': settings.templateColor.clrHex,
		'template-text': settings.templateTextColor,
		'button-color': settings.buttonStyle.color,
		'button-text': settings.buttonStyle.textColor,
		'header-color': settings.headerStyle.color,
		'header-text': settings.headerStyle.textColor,
		'footer-color': settings.footerStyle.color,
		'footer-text': settings.footerStyle.textColor,
	};

	const dark = settings.templateCode === 'dark-one';

	const opts = {
		icons: {
			iconfont: 'mdiSvg',
		},
		lang: {
			locales: {
				en,
				tr,
			},
			current: 'tr',
		},
		theme: {
			dark,
			themes: {
				dark: {
					background: '#ffffff',
					'lg-primary-text': '#4E4E4E',
					'lg-subheader-text': '#ffffff',
					'lg-header': '#272727',
					'lg-header-link-1': '#4e4e4e',
					'lg-damageapart-text': '#949494',
					'template-color': '#212121',
					'template-text': '#ffffff',
					...colors,
				},
				light: {
					'lg-primary-text': '#4E4E4E',
					'lg-subheader-text': '#4e4e4e',
					'lg-header': '#1E81F6',
					'lg-header-link-1': '#4e4e4e',
					'lg-damage-bg': '#F3F3F3',
					'lg-damageapart-text': '#A0A0A0',
					'template-color': '#2196F3',
					'template-text': '#ffffff',
					...colors,
				},
			},
			options: {
				minifyTheme,
				customProperties: true,
			},
		},
	};
	return new Vuetify(opts);
}
